.header {
  height: 200px;
  width: 100%;
  background-image: url("./img/slatchain-bg.jpg");
  background-size: cover;
  background-position: center center;
}
.overlay-slat {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay-slat > h4 {
  margin: 0%;
  font-size: 4rem;
  font-family: "Titillium Web", sans-serif;
  color: white;
}

.info-slatchain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding: 25px 0px 25px 0px;
  margin: 0%;
  font-family: "Titillium Web", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}
.info-slatchain > h4 {
  font-family: "Ubuntu", sans-serif;
  font-size: 2rem;
  margin: 0%;
}
.info-slatchain > p {
  max-width: 85%;
}

.types {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  padding: 25px 0px 25px 0px;
  flex-wrap: wrap;
}
.simpleproducts {
  width: 275px;
  height: 300px;
  transition: all 0.2s ease-in-out;
}
.types > div:hover .simpleproduct {
  background-color: black;
  transform: scale(1.05);
}
.types > a:hover .simpleproduct {
  background-color: black;
  transform: scale(1.05);
}
.prods-img {
  width: 100%;
  height: 90%;
  object-fit: unset;
}

@media (max-width: 1000px) {
  .types {
    flex-wrap: wrap;
  }
  .simpleproducts {
    justify-content: flex-start;
  }
}
