.header-roll {
  height: 200px;
  width: 100%;
  background-image: url("./img/roller-chain-bg.jpg");
  background-size: cover;
  background-position: center center;
}
.head-ele2 {
  background-color: #e9ecef;
  font-family: "Ubuntu", sans-serif;
  padding: 5px 10px 5px 10px;
}

tbody > tr > th,
tbody > tr > td {
  /* padding: 5px;
  padding-right: 25px;
  padding-left: 5px; */
  text-align: center;
  font-family: "Titillium Web", sans-serif;
  font-weight: bold;
}
.img-roll {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  gap: 20px;
  margin: 0px auto;
}
.img-roll > img {
  height: 200px;
  object-fit: contain;
}
@media (max-width: 1000px) {
  .roll-table {
    overflow: scroll;
    padding: 10px;
  }
  .img-roll > img {
    width: 150px;
    object-fit: contain;
  }
  .img-roll {
    max-width: none;
    gap: 5px;
  }
}
