.header-con {
  background-image: url("./img/conveyor-bg.jpg");
  height: 200px;
  width: 100%;
  background-size: cover;
  background-position: center center;
}

.con-slide {
  height: 450px;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 25px auto;
}
.slide {
  flex-direction: column;
}
.img {
  border-radius: 12px;
  /* box-shadow: 2px 4px 4px 2px #b4b3b3; */
  /* margin-bottom: 15px; */
}

.sc-bdnxRM {
  margin: 0px auto;
}

.img-div {
  text-align: center;
  margin-bottom: 25px;
}
.img-div > h4 {
  font-size: 1.5rem;
  font-family: "Ubuntu", sans-serif;
  margin: 10px;
}

.header-mesh {
  background-image: url("./img/mesh-Belt.jpg");
  height: 200px;
  width: 100%;
  background-size: cover;
  background-position: center center;
}
